import { Colors, Container, NormalText } from '@curvo/common-ui'
import * as React from 'react'
import styled from 'styled-components'
import { MAX_SMALL_IPAD_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../../components/GlobalStyles'
import backgroundImg from '../../images/Company/contact-bg.svg'

/////////////////////////
// styled components
/////////////////////////
const BackgroundContainer = styled.div`
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  margin-bottom: 88px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    margin-bottom: 64px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 56px;
  }
`
const CustomContainer = styled(Container)`
  flex-direction: column;
  align-items: center;
  padding-top: 242px;
  padding-bottom: 66px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    padding-top: 190px;
    padding-bottom: 102px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding-top: 116px;
    padding-bottom: 0px;
  }
`
const TitleText = styled(NormalText)`
  border-left: 4px solid ${Colors.Primary};
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 50px;
  }
`
const EmailLink = styled(NormalText)`
  :hover {
    text-decoration: underline;
  }
`

/////////////////////////
// component classes
/////////////////////////
const CompanyContact: React.SFC = () => (
  <BackgroundContainer>
    <CustomContainer>
      <TitleText className="section-title">Reach Us At</TitleText>
      <a href="mailto:support@curvolabs.com">
        <EmailLink className="small-title" primary marginBottom="14px">
          support@curvolabs.com
        </EmailLink>
      </a>
    </CustomContainer>
  </BackgroundContainer>
)

export default CompanyContact
