import { Container, NormalText } from '@curvo/common-ui'
import * as React from 'react'
import styled from 'styled-components'
import { MAX_SMALL_IPAD_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../../components/GlobalStyles'

/////////////////////////
// styled components
/////////////////////////
const CustomContainer = styled(Container)`
  flex-direction: column;
  margin-bottom: 168px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    margin-bottom: 136px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 86px;
  }
`
const TitleText = styled(NormalText)`
  margin-bottom: 34px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 20px;
    justify-content: center;
    text-align: center;
  }
`
const BodyText = styled(NormalText)`
  max-width: 920px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    max-width: 100%;
    text-align: center;
  }
`

/////////////////////////
// component classes
/////////////////////////
const CompanyTitle: React.SFC = () => (
  <CustomContainer>
    <TitleText className="hero-title">Company</TitleText>
    <BodyText secondary>
      We’re in the business of bettering patient's lives. We want to help your hospital improve your
      supply chain, leading to more effective orthopedic products and cheaper healthcare for your
      patients, empowering them to live the life they deserve.
    </BodyText>
  </CustomContainer>
)

export default CompanyTitle
