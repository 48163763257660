import { Colors, Container, Flex, NormalText } from '@curvo/common-ui'
import * as React from 'react'
import styled from 'styled-components'
import { MAX_SMALL_IPAD_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../../components/GlobalStyles'
import portraitAnnie from '../../images/Company/annie.jpg'
import portraitKathy from '../../images/Company/kathy.jpg'
import portraitBg from '../../images/Company/portrait-bg.svg'
import portraitPlaceholder from '../../images/Company/portrait-placeholder.svg'
import portraitRobin from '../../images/Company/robin.jpg'
import portraitStan from '../../images/Company/stan.jpg'

/////////////////////////
// data
/////////////////////////
const whoWeAreData = [
  {
    name: 'Stan Mendenhall',
    subTitle: 'Founder & Publisher',
    img: portraitStan,
  },
  { name: 'Robin Mendenhall', subTitle: 'Author & Artist', img: portraitRobin },
  {
    name: 'Annie Gallup',
    subTitle: 'Graphic Designer / Illustrator',
    img: portraitAnnie,
  },
  { name: 'Kathy Cote', subTitle: 'Research Analyst', img: portraitKathy },
  { name: 'Mary Moss', subTitle: 'Data Analyst' },
  { name: 'Lynda Dziedzic', subTitle: 'Data Analyst' },
]

/////////////////////////
// styled components
/////////////////////////
const TitleContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    margin-bottom: 56px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 72px;
  }
`
const SectionTitle = styled(NormalText)`
  border-left: 4px solid ${Colors.Primary};
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 34px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 26px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 26px;
  }
`
const BodyText = styled(NormalText)`
  width: 772px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    width: 512px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    width: 100%;
  }
`
const CustomGrid = styled(Flex)`
  flex-direction: column;
  margin-bottom: -50px;

  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: -72px;
  }
`
const GridRow = styled(Flex)`
  margin-right: -40px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-right: 0;
    flex-direction: column;
  }
`
const GridCard = styled(Flex)`
  flex: 1;
  margin-right: 40px;
  margin-bottom: 50px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-right: 0;
    margin-bottom: 72px;
    flex-direction: column;
  }
`
const CardImage = styled(Flex)`
  position: relative;
  width: 138px;
  height: 138px;
  margin-right: 24px;
  img {
    position: absolute;
    width: 110px;
  }
  .Portrait {
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 50%;
    border-top-right-radius: 50%;
    width: 124px;
    height: 124px;
  }

  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    width: 120px;
    height: 120px;
    img {
      width: 96px;
    }
    .Portrait {
      width: 108px;
      height: 108px;
    }
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    align-self: center;
    width: 104px;
    height: 104px;
    margin-right: 0px;
    margin-bottom: 16px;
    img {
      width: 82px;
    }
    .Portrait {
      width: 94px;
      height: 94px;
    }
  }
`
const CardTitle = styled(NormalText)`
  margin-bottom: 12px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 14px;
    align-self: center;
  }
`
const CardSubTitle = styled(NormalText)`
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    align-self: center;
  }
`

/////////////////////////
// component classes
/////////////////////////
const CompanyWhoWeAre: React.SFC = () => (
  <Container flexDirection="column">
    <TitleContainer>
      <SectionTitle className="section-title">Who we are</SectionTitle>
      <BodyText secondary center>
        We’re a team of passionate industry experts at ONN, each living by our mission — to create
        cheaper healthcare and improve orthopedic products, enabling people to live a happier,
        active life.
      </BodyText>
    </TitleContainer>
    <CustomGrid>
      {whoWeAreData &&
        !!whoWeAreData.length &&
        whoWeAreData
          .reduce<any[][]>((preVal, currVal, curIndex) => {
            if (curIndex % 2 === 0) {
              preVal.push([currVal])
            } else {
              preVal[Math.floor(curIndex / 2)].push(currVal)
            }
            return preVal
          }, [])
          .map((row, rowIndex) => (
            <GridRow key={'company-grid-row' + rowIndex}>
              {row.map((people, index) => (
                <GridCard key={'people-card-' + index}>
                  <CardImage>
                    <img src={portraitBg} />
                    <img className="Portrait" src={people.img ? people.img : portraitPlaceholder} />
                  </CardImage>
                  <Flex flexDirection="column" alignSelf="center">
                    <CardTitle className="medium-title">{people.name}</CardTitle>
                    <CardSubTitle secondary>{people.subTitle}</CardSubTitle>
                  </Flex>
                </GridCard>
              ))}
            </GridRow>
          ))}
      <Flex marginBottom="120px" />
    </CustomGrid>
  </Container>
)

export default CompanyWhoWeAre
