import * as React from 'react'
import styled from 'styled-components'
import BackgroundBigRect from '../components/BackgroundBigReact'
import BackgroundSmallRect from '../components/BackgroundSmallRect'
import { CompanyContact, CompanyTitle, CompanyWhoWeAre } from '../components/Company'
import { MAX_SMALL_IPAD_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../components/GlobalStyles'
import Layout from '../components/layout'

/////////////////////////
// styled components
/////////////////////////
const BackgroundContainer = styled.div`
  position: relative;
  overflow: visible;
  margin-top: 56px;
  margin-bottom: 88px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    margin-top: 58px;
    margin-bottom: 64px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-top: 120px;
    margin-bottom: 56px;
  }
`

/////////////////////////
// component classes
/////////////////////////
const Company = () => (
  <Layout>
    <BackgroundContainer>
      <BackgroundBigRect />
      <BackgroundSmallRect />
      <CompanyTitle />
      <CompanyWhoWeAre />
      <CompanyContact />
    </BackgroundContainer>
  </Layout>
)

export default Company
